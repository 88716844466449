<template>
  <div v-if="validador($route.meta.typePerfilVerificaRouta, getUserDatas.profile_id)">
    <alunoMeusTemasExercicioComponent :getUserDatas="getUserDatas" />
  </div>
</template>

<script>
import alunoMeusTemasExercicioComponent from "@/components/aluno/alunoMeusTemasExercicioComponent";
import {mapGetters} from 'vuex'

export default {
  components: {
    alunoMeusTemasExercicioComponent,
  },
  computed:{
    ...mapGetters(['getUserDatas'])
  },
};
</script>

<style></style>
